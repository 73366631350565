//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import LayDesktopFooter from '~/components/layouts/LayDesktopFooter'
import LayDesktopHeader from '~/components/layouts/LayDesktopHeader'

export default defineComponent({
    components: {
        LayDesktopHeader,
        LayDesktopFooter
    },
    computed: {
        isNoFooter () {
            return ['help', 'help-category', 'help-a-plink', 'reviews-permalink'].includes(this.$route.name)
        },
        appDesktopMainClass () {
            return this.isHelpPage
                ? 'app-desktop-main m-help-layout'
                : 'app-desktop-main'
        },
        isHelpPage () {
            return this.$route.matched.map(r => r.path).includes('/help')
        }
    }
})
