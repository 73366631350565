import Vue from 'vue'
import { findTag, measureEl } from '~/utils/helpers'

const getTooltipPosition = ($tooltip, el, modifiers) => {
    let left
    let top
    const $tooltipDimension = el.getBoundingClientRect()
    const { width, height } = measureEl($tooltip, $t => ({ width: $t.offsetWidth, height: $t.offsetHeight }))

    const leftPosition = $tooltipDimension.left - width
    const rightPosition = $tooltipDimension.left + el.offsetWidth
    const topPosition = $tooltipDimension.top - height
    const bottomPosition = $tooltipDimension.top + el.offsetHeight
    const topCenterPosition = $tooltipDimension.top + el.offsetHeight / 2 - height / 2
    const leftCenterPosition = $tooltipDimension.left + el.offsetWidth / 2 - width / 2

    if (modifiers.right) {
        $tooltip.setAttribute('x-placement', rightPosition + width >= window.innerWidth ? 'left' : 'right')
        left = rightPosition + width >= window.innerWidth ? leftPosition : rightPosition
        top = topCenterPosition
    } else if (modifiers.top) {
        $tooltip.setAttribute('x-placement', topPosition < 0 ? 'bottom' : 'top')
        left = leftCenterPosition
        top = topPosition < 0 ? bottomPosition : topPosition
    } else if (modifiers.bottom) {
        $tooltip.setAttribute('x-placement', topPosition + height >= window.innerHeight ? 'bottom' : 'top')
        left = leftCenterPosition
        top = topPosition + height >= window.innerHeight ? bottomPosition : topPosition
    } else {
        $tooltip.setAttribute('x-placement', leftPosition < 0 ? 'right' : 'left')
        left = leftPosition < 0 ? rightPosition : leftPosition
        top = topCenterPosition
    }
    return { top, left }
}

Vue.directive('truncate-tooltip', {
    bind: (el, binding, _vNode) => {
        if (process.server) { return }
        let timerId = null
        setTimeout(() => {
            // catch text overflow
            if (el.offsetWidth < el.scrollWidth) {
                const id = `truncate-tooltip-${Math.round(Math.random() * 100000000)}`
                const createTooltip = function () {
                    const $tooltip = document.createElement('div')
                    $tooltip.setAttribute('class', `udimi-tooltip truncate-tooltip ${binding.modifiers.fixed ? 'fixed' : ''}`)
                    $tooltip.setAttribute('id', id)

                    const arrow = document.createElement('span')
                    arrow.setAttribute('class', 'tooltip-arrow')

                    const inner = document.createElement('span')
                    inner.setAttribute('class', 'tooltip-inner')
                    inner.innerHTML = binding.value || el.innerHTML

                    $tooltip.appendChild(arrow)
                    $tooltip.appendChild(inner)

                    const { top, left } = getTooltipPosition($tooltip, el, binding.modifiers)

                    $tooltip.style.left = left + 'px'
                    $tooltip.style.top = top + (binding.modifiers.fixed ? 0 : window.scrollY) + 'px'
                    el.__vueTruncateTooltipId__ = id
                    document.body.appendChild($tooltip)

                    $tooltip.addEventListener('mouseenter', () => {
                        if (timerId) {
                            clearInterval(timerId)
                        }
                    })
                    $tooltip.addEventListener('mouseleave', (e) => {
                        e.target.parentNode?.removeChild(e.target)
                    })
                }
                const removeTooltip = function () {
                    const elemToRemove = document.getElementById(el.__vueTruncateTooltipId__)
                    if (elemToRemove) {
                        timerId = setTimeout(() => {
                            elemToRemove.parentNode?.removeChild(elemToRemove)
                            el.__vueTruncateTooltipId__ = null
                            timerId = null
                        }, 0)
                    }
                }

                el.__vueTruncateTooltipBind__ = createTooltip
                el.addEventListener('mouseenter', createTooltip)

                el.__vueTruncateTooltipUnbind__ = removeTooltip;
                ['mouseleave', 'click'].forEach((event) => {
                    el.addEventListener(event, removeTooltip)
                })
            }
        }, 0)
    },
    unbind: (el) => {
        if (process.server) { return }

        if (el?.__vueTruncateTooltipId__) {
            const elemToRemove = document.getElementById(el.__vueTruncateTooltipId__)
            if (elemToRemove) {
                elemToRemove.parentNode?.removeChild(elemToRemove)
                el.__vueTruncateTooltipId__ = null
            }
        }

        document.removeEventListener('mouseenter', el.__vueTruncateTooltipBind__)
        el.__vueTruncateTooltipBind__ = null;

        ['mouseleave', 'click'].forEach((event) => {
            el.removeEventListener(event, el.__vueTruncateTooltipUnbind__)
        })
        el.__vueTruncateTooltipUnbind__ = null
    }
})

Vue.directive('click-outside', {
    bind: (el, binding, _vNode) => {
        if (process.server) { return }
        const handler = (e) => {
            // "offsetParent === null" is for invisible elements; it may not work with fixed style
            if (e.target.offsetParent !== null && !el.contains(e.target) && el !== e.target) {
                binding.value(e)
            }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
    },
    unbind: (el) => {
        if (process.server) { return }
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
    }
})

Vue.directive('key-escape', {
    bind: (el, binding, _vNode) => {
        if (process.server) { return }
        const handler = (e) => {
            if (e.keyCode === 27) {
                binding.value(e)
            }
        }
        el.__vueClickEscape__ = handler
        document.addEventListener('keyup', handler)
    },
    unbind: (el) => {
        if (process.server) { return }
        document.removeEventListener('keyup', el.__vueClickEscape__)
        el.__vueClickEscape__ = null
    }
})

Vue.directive('remember', {
    bind: (el, binding, _vNode) => {
        if (process.server || !binding.expression) { return }
        const loadSavedInputsValue = () => {
            return localStorage.getItem('savedInputsValue') ? JSON.parse(localStorage.getItem('savedInputsValue')) : {}
        }

        const input = findTag(el, ['input', 'textarea'])
        const savedInputsValue = loadSavedInputsValue()
        const currentValue = savedInputsValue && savedInputsValue[binding.expression]
        if (currentValue) {
            input.value = currentValue
            input.dispatchEvent(new CustomEvent('input'))
        }
        const handler = () => {
            const updatedInputsValue = {}
            const savedInputsValue = loadSavedInputsValue()
            if (savedInputsValue) { Object.assign(updatedInputsValue, savedInputsValue) }
            updatedInputsValue[binding.expression] = input.value
            localStorage.setItem('savedInputsValue', JSON.stringify(updatedInputsValue))
        }

        el.__inputBlur__ = handler
        input.addEventListener('blur', handler)
    },
    unbind: (el) => {
        if (process.server) { return }
        document.removeEventListener('blur', el.__inputBlur__)
        el.__inputBlur__ = null
    }
})

Vue.directive('autofocus', {
    inserted: function (el, binding) {
        const isNeed = binding.value === undefined || binding.value
        if (isNeed) {
            const input = findTag(el, ['input', 'textarea'])
            input.focus()
        }
    }
})
