import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62de29e2 = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _06da98fe = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _317771f1 = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _5c543ea4 = () => interopDefault(import('../pages/buy-solo-ads.vue' /* webpackChunkName: "pages/buy-solo-ads" */))
const _f20f8bb2 = () => interopDefault(import('../pages/buy-solo-ads/index.vue' /* webpackChunkName: "pages/buy-solo-ads/index" */))
const _2b1a6e95 = () => interopDefault(import('../pages/buy-solo-ads/niche/_slug.vue' /* webpackChunkName: "pages/buy-solo-ads/niche/_slug" */))
const _639f9374 = () => interopDefault(import('../pages/dev-login.vue' /* webpackChunkName: "pages/dev-login" */))
const _856e8a9a = () => interopDefault(import('../pages/filter.vue' /* webpackChunkName: "pages/filter" */))
const _80f2263e = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _0763ee36 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _0ed3178e = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _21bb8e31 = () => interopDefault(import('../pages/forum/comment/_id.vue' /* webpackChunkName: "pages/forum/comment/_id" */))
const _ffa4075c = () => interopDefault(import('../pages/forum/_section/index.vue' /* webpackChunkName: "pages/forum/_section/index" */))
const _45d6cb85 = () => interopDefault(import('../pages/forum/_section/_idSlug.vue' /* webpackChunkName: "pages/forum/_section/_idSlug" */))
const _7a937ebc = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _3e5d083f = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _c8771108 = () => interopDefault(import('../pages/help/support.vue' /* webpackChunkName: "pages/help/support" */))
const _4825a8fc = () => interopDefault(import('../pages/help/a/_plink.vue' /* webpackChunkName: "pages/help/a/_plink" */))
const _5c75c46c = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _3c93a383 = () => interopDefault(import('../pages/help/_unregistered/_plink.vue' /* webpackChunkName: "pages/help/_unregistered/_plink" */))
const _26adec7e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _22803026 = () => interopDefault(import('../pages/logo.vue' /* webpackChunkName: "pages/logo" */))
const _313175bd = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _1f843494 = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _204f0b81 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _26eb8f73 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _20fc863a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _8f2f3fe4 = () => interopDefault(import('../pages/search/_unregistered.vue' /* webpackChunkName: "pages/search/_unregistered" */))
const _092cdf5a = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _55de9f1d = () => interopDefault(import('../pages/solodeals.vue' /* webpackChunkName: "pages/solodeals" */))
const _080c9be0 = () => interopDefault(import('../pages/solodeals/index.vue' /* webpackChunkName: "pages/solodeals/index" */))
const _8d0bde52 = () => interopDefault(import('../pages/solodeals/price.vue' /* webpackChunkName: "pages/solodeals/price" */))
const _b5748cec = () => interopDefault(import('../pages/solodeals/search.vue' /* webpackChunkName: "pages/solodeals/search" */))
const _6ab2a60d = () => interopDefault(import('../pages/solodeals/_post.vue' /* webpackChunkName: "pages/solodeals/_post" */))
const _654be38d = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _28edc09a = () => interopDefault(import('../pages/test-apple.vue' /* webpackChunkName: "pages/test-apple" */))
const _6269e7fa = () => interopDefault(import('../pages/test-cases.vue' /* webpackChunkName: "pages/test-cases" */))
const _2a0b32f6 = () => interopDefault(import('../pages/test-cases/page1.vue' /* webpackChunkName: "pages/test-cases/page1" */))
const _2a194a77 = () => interopDefault(import('../pages/test-cases/page2.vue' /* webpackChunkName: "pages/test-cases/page2" */))
const _781b91c4 = () => interopDefault(import('../pages/test-owl.vue' /* webpackChunkName: "pages/test-owl" */))
const _70e548aa = () => interopDefault(import('../pages/udimi-reviews-video/index.vue' /* webpackChunkName: "pages/udimi-reviews-video/index" */))
const _0f75e080 = () => interopDefault(import('../pages/competition/hall-of-fame.vue' /* webpackChunkName: "pages/competition/hall-of-fame" */))
const _536934d2 = () => interopDefault(import('../pages/notifications/instantunsubscribe.vue' /* webpackChunkName: "pages/notifications/instantunsubscribe" */))
const _4af38da5 = () => interopDefault(import('../pages/notifications/settings.vue' /* webpackChunkName: "pages/notifications/settings" */))
const _55eb6908 = () => interopDefault(import('../pages/profile/custom-route/recommends.vue' /* webpackChunkName: "pages/profile/custom-route/recommends" */))
const _5d064967 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0e37939f = () => interopDefault(import('../pages/competition/movers-and-shakers/_month.vue' /* webpackChunkName: "pages/competition/movers-and-shakers/_month" */))
const _750dd652 = () => interopDefault(import('../pages/articles/_plink.vue' /* webpackChunkName: "pages/articles/_plink" */))
const _2d938316 = () => interopDefault(import('../pages/ratings/_month.vue' /* webpackChunkName: "pages/ratings/_month" */))
const _3269a256 = () => interopDefault(import('../pages/restore/_token.vue' /* webpackChunkName: "pages/restore/_token" */))
const _7517e29b = () => interopDefault(import('../pages/reviews/_permalink.vue' /* webpackChunkName: "pages/reviews/_permalink" */))
const _1338be08 = () => interopDefault(import('../pages/share/_uid.vue' /* webpackChunkName: "pages/share/_uid" */))
const _2f2af26a = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _4cb2e574 = () => interopDefault(import('../pages/udimi-reviews-video/_page.vue' /* webpackChunkName: "pages/udimi-reviews-video/_page" */))
const _43cf2fff = () => interopDefault(import('../pages/solo/_uid/_anySubRoute.vue' /* webpackChunkName: "pages/solo/_uid/_anySubRoute" */))
const _1900cadc = () => interopDefault(import('../pages/_unregistered/index.vue' /* webpackChunkName: "pages/_unregistered/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _62de29e2,
    name: "affiliate"
  }, {
    path: "/affiliates",
    component: _06da98fe,
    name: "affiliates"
  }, {
    path: "/awards",
    component: _317771f1,
    name: "awards"
  }, {
    path: "/buy-solo-ads",
    component: _5c543ea4,
    children: [{
      path: "",
      component: _f20f8bb2,
      name: "buy-solo-ads"
    }, {
      path: "niche/:slug?",
      component: _2b1a6e95,
      name: "buy-solo-ads-niche-slug"
    }]
  }, {
    path: "/dev-login",
    component: _639f9374,
    name: "dev-login"
  }, {
    path: "/filter",
    component: _856e8a9a,
    name: "filter"
  }, {
    path: "/forgot",
    component: _80f2263e,
    name: "forgot"
  }, {
    path: "/forum",
    component: _0763ee36,
    children: [{
      path: "",
      component: _0ed3178e,
      name: "forum"
    }, {
      path: "comment/:id?",
      component: _21bb8e31,
      name: "forum-comment-id"
    }, {
      path: ":section",
      component: _ffa4075c,
      name: "forum-section"
    }, {
      path: ":section/:idSlug",
      component: _45d6cb85,
      name: "forum-section-idSlug"
    }]
  }, {
    path: "/help",
    component: _7a937ebc,
    children: [{
      path: "",
      component: _3e5d083f,
      name: "help"
    }, {
      path: "support",
      component: _c8771108,
      name: "help-support"
    }, {
      path: "a/:plink?",
      component: _4825a8fc,
      name: "help-a-plink"
    }, {
      path: ":category",
      component: _5c75c46c,
      name: "help-category"
    }, {
      path: ":unregistered/:plink?",
      component: _3c93a383,
      name: "help-unregistered-plink"
    }]
  }, {
    path: "/login",
    component: _26adec7e,
    name: "login"
  }, {
    path: "/logo",
    component: _22803026,
    name: "logo"
  }, {
    path: "/logos",
    component: _313175bd,
    name: "logos"
  }, {
    path: "/prime",
    component: _1f843494,
    name: "prime"
  }, {
    path: "/profile",
    component: _204f0b81,
    name: "profile"
  }, {
    path: "/reviews",
    component: _26eb8f73,
    name: "reviews"
  }, {
    path: "/search",
    component: _20fc863a,
    name: "search",
    children: [{
      path: ":unregistered?",
      component: _8f2f3fe4,
      name: "search-unregistered"
    }]
  }, {
    path: "/signup",
    component: _092cdf5a,
    name: "signup"
  }, {
    path: "/solodeals",
    component: _55de9f1d,
    children: [{
      path: "",
      component: _080c9be0,
      name: "solodeals"
    }, {
      path: "price",
      component: _8d0bde52,
      name: "solodeals-price"
    }, {
      path: "search",
      component: _b5748cec,
      name: "solodeals-search"
    }, {
      path: ":post",
      component: _6ab2a60d,
      name: "solodeals-post"
    }]
  }, {
    path: "/test",
    component: _654be38d,
    name: "test"
  }, {
    path: "/test-apple",
    component: _28edc09a,
    name: "test-apple"
  }, {
    path: "/test-cases",
    component: _6269e7fa,
    name: "test-cases",
    children: [{
      path: "page1",
      component: _2a0b32f6,
      name: "test-cases-page1"
    }, {
      path: "page2",
      component: _2a194a77,
      name: "test-cases-page2"
    }]
  }, {
    path: "/test-owl",
    component: _781b91c4,
    name: "test-owl"
  }, {
    path: "/udimi-reviews-video",
    component: _70e548aa,
    name: "udimi-reviews-video"
  }, {
    path: "/competition/hall-of-fame",
    component: _0f75e080,
    name: "competition-hall-of-fame"
  }, {
    path: "/notifications/instantunsubscribe",
    component: _536934d2,
    name: "notifications-instantunsubscribe"
  }, {
    path: "/notifications/settings",
    component: _4af38da5,
    name: "notifications-settings"
  }, {
    path: "/profile/custom-route/recommends",
    component: _55eb6908,
    name: "profile-custom-route-recommends"
  }, {
    path: "/",
    component: _5d064967,
    name: "index"
  }, {
    path: "/competition/movers-and-shakers/:month?",
    component: _0e37939f,
    name: "competition-movers-and-shakers-month"
  }, {
    path: "/articles/:plink?",
    component: _750dd652,
    name: "articles-plink"
  }, {
    path: "/ratings/:month?",
    component: _2d938316,
    name: "ratings-month"
  }, {
    path: "/restore/:token?",
    component: _3269a256,
    name: "restore-token"
  }, {
    path: "/reviews/:permalink",
    component: _7517e29b,
    name: "reviews-permalink"
  }, {
    path: "/share/:uid?",
    component: _1338be08,
    name: "share-uid"
  }, {
    path: "/token/:token?",
    component: _2f2af26a,
    name: "token-token"
  }, {
    path: "/udimi-reviews-video/:page?",
    component: _4cb2e574,
    name: "udimi-reviews-video-page"
  }, {
    path: "/solo/:uid?/:anySubRoute?",
    component: _43cf2fff,
    name: "solo-uid-anySubRoute"
  }, {
    path: "/:unregistered",
    component: _1900cadc,
    name: "unregistered"
  }, {
    path: "/u/:hash",
    component: _536934d2,
    name: "u-hash"
  }, {
    path: "/u/settings/:hash",
    component: _4af38da5,
    name: "u-settings-hash"
  }, {
    path: "/a/:uid",
    component: _5d064967,
    name: "affiliate-regular"
  }, {
    path: "/prime/:uid([a-zA-Z0-9]{5})",
    component: _5d064967,
    name: "affiliate-prime"
  }, {
    path: "/p/:uid_profile",
    component: _204f0b81,
    name: "profile-index"
  }, {
    path: "/p/:uid_profile/recommends",
    component: _55eb6908,
    name: "profile-recommends"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
