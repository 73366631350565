export const state = () => ({
    local: {
        index: {
            DATE_FORMAT: 'D MMM YYYY',
            FORUM_DATE_FORMAT: 'D MMM YYYY [at] HH:mm',
            SERVER_DATE_FORMAT: 'YYYY-MM-DD'
        },
        seller: {
            FILTER_CLASS_ONLY_MOBILE: 'only-mobile',
            FILTER_CLASS_NO_MOBILE: 'mobile',
            FILTER_CLASS_PRIME_FILTER: 'prime-filter',
            FILTER_CLASS_BASE: 'base',

            AD_TYPE_LINK: 'link',
            AD_TYPE_SWIPE: 'swipe'
        }
    },
    server: {
        support: {
            AVG_RESPONSE_TIME: 86400
        },
        competitions: {
            MAX_MONTH_PLACES: 100,
            MAS_TOP: 10,
            HOF_TOP: 15,
            HOF_START_YEAR: 2014
        },
        seller: {
            CERTIFICATE_LENGTH: 6
        }
    }
})

export const mutations = {
    setServer (state, payload) {
        const getByPath = (obj, str, def = null) => {
            const arrFromStr = str.split('.')
            for (let i = 0; i < arrFromStr.length; i++) {
                obj = obj[arrFromStr[i]]
                if (obj === undefined || obj === null) { return def }
            }
            return obj
        }
        const put = (pathState, pathPayload) => {
            const stateConstsObj = getByPath(state.server, pathState)
            const payloadConstsObj = getByPath(payload, pathPayload || pathState)
            for (const constName in stateConstsObj) {
                // console.log(`stateConstsObj.${constName} = ${stateConstsObj[constName]}`)
                if (payloadConstsObj &&
                    payloadConstsObj[constName] !== undefined &&
                    typeof payloadConstsObj[constName] !== 'object' &&
                    payloadConstsObj[constName] !== null
                ) {
                    // console.log('Exists: ', constName)
                    stateConstsObj[constName] = payloadConstsObj[constName]
                }
            }
        }

        put('support')
        put('competitions')
    }
}
