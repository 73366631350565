/* eslint-disable */
export default function ({ $config }) {
    if ($config.isDev) {
        return
    }
    (function(){
        var a = document.createElement('script');
        a.type = 'text/javascript';a.async=1;
        a.src='https://api.udimi.com/v1/clients/core/acd3c718c972e7f9/script.js';
        var h = document.head || document.getElementsByTagName('head')[0];
        h.appendChild(a);
    })();
}