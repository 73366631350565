import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_9060267e from 'nuxt_plugin_plugin_9060267e' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_1e98ea1b from 'nuxt_plugin_sentryserver_1e98ea1b' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_8e410eda from 'nuxt_plugin_sentryclient_8e410eda' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_12b3de2e from 'nuxt_plugin_plugin_12b3de2e' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_vuescrollto_99dc965c from 'nuxt_plugin_vuescrollto_99dc965c' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_279882ff from 'nuxt_plugin_cookieuniversalnuxt_279882ff' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_4c331d52 from 'nuxt_plugin_axios_4c331d52' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_40bec1e2 from 'nuxt_plugin_deviceplugin_40bec1e2' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_handleErrors_04d1b3ae from 'nuxt_plugin_handleErrors_04d1b3ae' // Source: ../plugins/handleErrors (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_cdn_5e4628f7 from 'nuxt_plugin_cdn_5e4628f7' // Source: ../plugins/cdn (mode: 'all')
import nuxt_plugin_avatar_77ae2762 from 'nuxt_plugin_avatar_77ae2762' // Source: ../plugins/avatar (mode: 'all')
import nuxt_plugin_polyfills_7dce8c4e from 'nuxt_plugin_polyfills_7dce8c4e' // Source: ../plugins/polyfills (mode: 'all')
import nuxt_plugin_moneyFormat_72df56b6 from 'nuxt_plugin_moneyFormat_72df56b6' // Source: ../plugins/moneyFormat.js (mode: 'all')
import nuxt_plugin_helpers_1c816d70 from 'nuxt_plugin_helpers_1c816d70' // Source: ../plugins/helpers.js (mode: 'all')
import nuxt_plugin_pluralize_160c0c9a from 'nuxt_plugin_pluralize_160c0c9a' // Source: ../plugins/pluralize (mode: 'all')
import nuxt_plugin_globalMixin_2ba03b8f from 'nuxt_plugin_globalMixin_2ba03b8f' // Source: ../plugins/globalMixin.js (mode: 'all')
import nuxt_plugin_legacyCookies_171b76d0 from 'nuxt_plugin_legacyCookies_171b76d0' // Source: ../plugins/legacyCookies (mode: 'server')
import nuxt_plugin_dialogs_37dad9e2 from 'nuxt_plugin_dialogs_37dad9e2' // Source: ../plugins/dialogs.js (mode: 'client')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'client')
import nuxt_plugin_notifications_36de4932 from 'nuxt_plugin_notifications_36de4932' // Source: ../plugins/notifications (mode: 'client')
import nuxt_plugin_tooltip_a5fff1ec from 'nuxt_plugin_tooltip_a5fff1ec' // Source: ../plugins/tooltip.js (mode: 'client')
import nuxt_plugin_fingerprint_bf5d68ee from 'nuxt_plugin_fingerprint_bf5d68ee' // Source: ../plugins/fingerprint.js (mode: 'client')
import nuxt_plugin_fullScreenLoader_29476344 from 'nuxt_plugin_fullScreenLoader_29476344' // Source: ../plugins/fullScreenLoader (mode: 'client')
import nuxt_plugin_dayjs_66ce5f68 from 'nuxt_plugin_dayjs_66ce5f68' // Source: ../plugins/dayjs.js (mode: 'all')
import nuxt_plugin_dayjsUTC_29db2a3c from 'nuxt_plugin_dayjsUTC_29db2a3c' // Source: ../plugins/dayjsUTC.js (mode: 'all')
import nuxt_plugin_googleanal_05d3a530 from 'nuxt_plugin_googleanal_05d3a530' // Source: ../plugins/third-party/google-anal.js (mode: 'client')
import nuxt_plugin_udimi_3b909bf4 from 'nuxt_plugin_udimi_3b909bf4' // Source: ../plugins/third-party/udimi.js (mode: 'client')
import nuxt_plugin_meta_657f123b from 'nuxt_plugin_meta_657f123b' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    // @ts-ignore
    const siteUrl = `${this?.$config?.siteProtocol}://${this?.$config?.siteHost}`;
    // @ts-ignore
    const path = this?.$route ? `${siteUrl}${this.$route.path}` : siteUrl;

    return {
      title: 'Buy solo ads - Udimi',
      htmlAttrs: {
        lang: 'en'
      },
      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'release', content: process.env.UDIMI_FRONT_OUTSIDE_RELEASE || '1.0.0' },
      {
        hid: 'description',
        name: 'description',
        content: 'Get Visitors, Optins & Sales. Today! ID-verified sellers, top-quality traffic filter and unique rating system will make your success inevitable'
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: 'Buy solos, sell solos, buy email solo ad, email solo ads, Udimi'
      },

      { name: 'og:title', property: 'og:title', hid: 'og:title', content: 'Udimi - Buy email solo ads' },
      { name: 'og:type', property: 'og:type', hid: 'og:type', content: 'website' },
      { name: 'og:url', property: 'og:url', hid: 'og:url', content: path },
      { name: 'og:description', property: 'og:description', hid: 'og:description', content: 'Buy Email Solo Ads' },
      { name: 'og:image', property: 'og:image', hid: 'og:image', content: `${siteUrl}/assets-outside/img/Udimi-buy-solo-fblogo.webp` },

      { name: 'twitter:card', hid: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', hid: 'twitter:title', content: 'Udimi - Buy email solo ads' },
      { name: 'twitter:description', hid: 'twitter:description', content: 'Buy Email Solo Ads' },
      { name: 'twitter:image', hid: 'twitter:image', content: `${siteUrl}/assets-outside/img/Udimi-buy-solo-fblogo.webp` }],

      link: [
      { rel: 'preload', as: 'image', fetchpriority: 'high', type: 'image/svg+xml', href: '/assets-outside/img/udimi-loader.svg' },
      { hid: 'favicon', rel: 'icon', type: 'image/x-icon', sizes: '16x16', href: '/assets-outside/img/favicon-16.png' },
      { hid: 'favicon', rel: 'icon', type: 'image/x-icon', sizes: '32x32', href: '/assets-outside/img/favicon-32.png' }]
    };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_9060267e === 'function') {
    await nuxt_plugin_plugin_9060267e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_1e98ea1b === 'function') {
    await nuxt_plugin_sentryserver_1e98ea1b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_8e410eda === 'function') {
    await nuxt_plugin_sentryclient_8e410eda(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_12b3de2e === 'function') {
    await nuxt_plugin_plugin_12b3de2e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_99dc965c === 'function') {
    await nuxt_plugin_vuescrollto_99dc965c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_279882ff === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_279882ff(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_4c331d52 === 'function') {
    await nuxt_plugin_axios_4c331d52(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_40bec1e2 === 'function') {
    await nuxt_plugin_deviceplugin_40bec1e2(app.context, inject)
  }

  if (typeof nuxt_plugin_handleErrors_04d1b3ae === 'function') {
    await nuxt_plugin_handleErrors_04d1b3ae(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_cdn_5e4628f7 === 'function') {
    await nuxt_plugin_cdn_5e4628f7(app.context, inject)
  }

  if (typeof nuxt_plugin_avatar_77ae2762 === 'function') {
    await nuxt_plugin_avatar_77ae2762(app.context, inject)
  }

  if (typeof nuxt_plugin_polyfills_7dce8c4e === 'function') {
    await nuxt_plugin_polyfills_7dce8c4e(app.context, inject)
  }

  if (typeof nuxt_plugin_moneyFormat_72df56b6 === 'function') {
    await nuxt_plugin_moneyFormat_72df56b6(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_1c816d70 === 'function') {
    await nuxt_plugin_helpers_1c816d70(app.context, inject)
  }

  if (typeof nuxt_plugin_pluralize_160c0c9a === 'function') {
    await nuxt_plugin_pluralize_160c0c9a(app.context, inject)
  }

  if (typeof nuxt_plugin_globalMixin_2ba03b8f === 'function') {
    await nuxt_plugin_globalMixin_2ba03b8f(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_legacyCookies_171b76d0 === 'function') {
    await nuxt_plugin_legacyCookies_171b76d0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dialogs_37dad9e2 === 'function') {
    await nuxt_plugin_dialogs_37dad9e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_36de4932 === 'function') {
    await nuxt_plugin_notifications_36de4932(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tooltip_a5fff1ec === 'function') {
    await nuxt_plugin_tooltip_a5fff1ec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fingerprint_bf5d68ee === 'function') {
    await nuxt_plugin_fingerprint_bf5d68ee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullScreenLoader_29476344 === 'function') {
    await nuxt_plugin_fullScreenLoader_29476344(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce5f68 === 'function') {
    await nuxt_plugin_dayjs_66ce5f68(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsUTC_29db2a3c === 'function') {
    await nuxt_plugin_dayjsUTC_29db2a3c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanal_05d3a530 === 'function') {
    await nuxt_plugin_googleanal_05d3a530(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_udimi_3b909bf4 === 'function') {
    await nuxt_plugin_udimi_3b909bf4(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_657f123b === 'function') {
    await nuxt_plugin_meta_657f123b(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
