//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'LayMobileMenu',
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        menuItems () {
            return [
                { path: '/login', icon: 'ud-arrow-square', label: 'Login' },
                { path: '/signup', icon: 'ud-add-user', label: 'Signup' },
                { path: '/buy-solo-ads', icon: 'ud-searching-magnifying-glass', label: 'Buy solo ads' },
                { path: '/solo-ads/udimi-affiliate-program/', icon: 'ud-briefcase', label: 'Affiliate program', regular: true },
                { path: '/forum', icon: 'ud-users', label: 'Forum' },
                { path: '/help', icon: 'ud-question-mark', label: 'Help' }
            ]
        }
    },
    watch: {
        isActive (val) {
            if (val) {
                document.body.style.overflowY = 'hidden'
            } else {
                document.body.style.overflowY = ''
            }
        }
    },
    beforeDestroy () {
        document.body.style.overflowY = ''
    }
}
