import ApiInterface from './ApiInterface'
import { CLIENT_TIMEZONE } from '~/constants'
import { CLIENT_APP_WEB, CLIENT_APP_WEB_MOBILE, CLIENT_MODE_DEVELOPMENT } from '~/constants/headers'

export default class ApiInterfaceAxios extends ApiInterface {
    constructor (ctx) {
        super(ctx)

        this.ctx.$axios.setHeader('X-FRONT-NODE', this.ctx.$config.appNode)
        this.ctx.$axios.setHeader('Client-App', ctx.$device?.isMobile ? CLIENT_APP_WEB_MOBILE : CLIENT_APP_WEB)
        if (ctx.$config.siteHost !== 'udimi.com') {
            this.ctx.$axios.setHeader('Client-Mode', CLIENT_MODE_DEVELOPMENT)
        }
        if (process.server && this.ctx.$config.ssrHeaderKey) {
            this.ctx.$axios.setHeader('Client-Ssr', this.ctx.$config.ssrHeaderKey)
        }
        if (!process.server) {
            if (CLIENT_TIMEZONE) {
                this.ctx.$axios.setHeader('Auth-Timezone-Name', CLIENT_TIMEZONE)
            }
        }

        this.ctx.$axios.onRequest((config) => {
            config.withCredentials = true
        })

        this.ctx.$axios.onResponse((res) => {
            const setCookiesHeader = res.headers['set-cookie']
            setCookiesHeader && this.proxyCookies(setCookiesHeader)
        })
    }

    get (url, params, options) {
        if (params) {
            url += this.buildQueryString(params)
        }
        return new Promise((resolve, reject) => {
            this.ctx.$axios.get(url, options)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    post (url, params, options) {
        return new Promise((resolve, reject) => {
            this.ctx.$axios.post(url, params, options)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    put (url, params, options) {
        return new Promise((resolve, reject) => {
            this.ctx.$axios.put(url, params, options)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    delete (url, options) {
        return new Promise((resolve, reject) => {
            this.ctx.$axios.delete(url, options)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }
}
